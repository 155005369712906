import { mapActions } from 'vuex'
export default {
  data () {
    return {
      agreementSetp: 1,
      userAgreement: 1
    }
  },
  created () {
    if (window.localStorage.getItem('userInfo')) {
      this.$router.push('/index')
    }
  },
  methods: {
    ...mapActions('menu', ['getAuthMenu']),
    login () {
      this.isLoading = !this.isLoading
      this.$http({
        url: '/api/v1/login',
        method: 'post',
        data: this.form
      }).then(async res => {
        this.isLoading = !this.isLoading
        let { data } = res
        window.localStorage.setItem('userName', data.teacher_name)
        window.localStorage.setItem('userInfo', JSON.stringify(data))
        window.localStorage.setItem("is_research", data.is_research)
        await this.getAuthMenu()
        this.$router.push('/index')
      })
    },
    activation () {
      console.log("222");
      this.$router.push('/teacherCard')
    },
    goToStep () {
      this.agreementSetp = 2
      this.userAgreement = 2;
    },


    onBackClick () {
      this.userAgreement = 1 // 延时1s再显示父组件
      let timer = setTimeout(() => {
        this.agreementSetp = 1
        clearTimeout(timer)
      }, 1100);
    }
  }
}